// Meals list (converted manually from CSV)

const meals = [
  {
    "recipe": "White bean quesadilla",
    "genre": "mexican",
    "primaryStarch": "tortilla",
    "rating": 4,
    "source": "modern way to cook",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Breakfast for dinner",
    "genre": "other",
    "primaryStarch": "potato",
    "rating": 4,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Vegetarian skillet chili",
    "genre": "mexican",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Scratchy husband pasta",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Spicy peanut noodles",
    "genre": "asian",
    "primaryStarch": "noodles",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "Ali Slaigle",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Salmon broccoli with ginger",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 2,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Pomelo noodles",
    "genre": "other",
    "primaryStarch": "pasta",
    "rating": 2,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Lauki sabzi",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 3,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Vegan mac & cheese",
    "genre": "other",
    "primaryStarch": "pasta",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Lauren Toyota's Vegan Cookbook",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Spinach and feta saag paneer",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 2,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Red pepper potato & peanut sabzi",
    "genre": "indian",
    "primaryStarch": "potato",
    "rating": 3,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Turmeric and coconut aloo gobi",
    "genre": "indian",
    "primaryStarch": "potato",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Golden miso potato salad",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Egg salad",
    "genre": "other",
    "primaryStarch": "bread",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Carrot poutine",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Trois Fois par Jour",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Chana masala",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Matar paneer",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Chile peanut rice",
    "genre": "indian",
    "primaryStarch": "rice",
    "rating": 3,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Caramelized ginger mushrooms",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Veggie stir fry",
    "genre": "asian",
    "primaryStarch": "rice",
    "rating": 3,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Veggie stir fry with peanut sauce",
    "genre": "asian",
    "primaryStarch": "rice",
    "rating": 4,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Sweet potatoes, cauliflour, and feta",
    "genre": "other",
    "primaryStarch": "sweet potato",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Pasta with tomatoes, capers, and olives",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Instant pot Thai curry",
    "genre": "asian",
    "primaryStarch": "rice",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Graham",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Gochujang fried rice",
    "genre": "asian",
    "primaryStarch": "rice",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Cheesy cauliflour toasts",
    "genre": "other",
    "primaryStarch": "bread",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Jollof rice",
    "genre": "other",
    "primaryStarch": "rice",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Tacos with quick pickled onions",
    "genre": "mexican",
    "primaryStarch": "tortilla",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 2,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Aloo gobi",
    "genre": "indian",
    "primaryStarch": "potato",
    "rating": 5,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Ricotta and lemon pasta",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 3,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Indianish nachos",
    "genre": "indian",
    "primaryStarch": "tortilla",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Instant pot chana dal",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Piping Pot Curry",
    "url": "https://pipingpotcurry.com/instant-pot-chana-dal/",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Tofu makhani",
    "genre": "indian",
    "primaryStarch": "naan",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Shrimp pad thai",
    "genre": "asian",
    "primaryStarch": "noodles",
    "rating": 4,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Veggie tofu pad thai",
    "genre": "asian",
    "primaryStarch": "noodles",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Minimalist Baker",
    "url": "https://minimalistbaker.com/easy-tofu-pad-thai/",
    "pageNumber": null,
    "multiplier": 2,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Ramen with veggies",
    "genre": "asian",
    "primaryStarch": "noodles",
    "rating": 4,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Ramen with cheese and egg",
    "genre": "asian",
    "primaryStarch": "noodles",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Double lime, asparagus, cashew, and ginger stir fry",
    "genre": "asian",
    "primaryStarch": "rice",
    "rating": 5,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Lemon and cardamom upside-down cake",
    "genre": "other",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Creamy miso pasta",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Polenta with tomato sauce",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 3,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Sweet potato & green chili masala dosa",
    "genre": "indian",
    "primaryStarch": "tortilla",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 124,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Leek, feta, and lemon polenta cake",
    "genre": "other",
    "primaryStarch": "",
    "rating": 2,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Avocado and popped bean burritos",
    "genre": "mexican",
    "primaryStarch": "tortilla",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Sweet potato enchiladas",
    "genre": "mexican",
    "primaryStarch": "tortilla",
    "rating": 4,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Mushroom bacon burgers",
    "genre": "other",
    "primaryStarch": "bread",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Lauren Toyota's Vegan Comfort Food",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Almond satay bowl with tempeh",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Vegan jackfruit pulled pork",
    "genre": "mexican",
    "primaryStarch": "bread",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Website",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Buffalo cauliflour wings",
    "genre": "asian",
    "primaryStarch": "bread",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Lauren Toyota",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Cacio e pepe with cashew cheese",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Mustard seed & curry carrots",
    "genre": "side",
    "primaryStarch": "",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Pav bhaji",
    "genre": "indian",
    "primaryStarch": "potato",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Cheesy chili broccoli",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Gnocchi with gorgonzola cheese sauce",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 4,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Gnocchi with tomato sauce",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 4,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Leek quiche",
    "genre": "other",
    "primaryStarch": "pie crust",
    "rating": 4,
    "source": "other",
    "sourceDetails": "The Kitchn",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Priya's dal",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Malaysian ramen",
    "genre": "asian",
    "primaryStarch": "noodles",
    "rating": 5,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 2,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Broccoli cheddar soup",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Copycat Panera recipe",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Salmon, potatoes, and snap peas",
    "genre": "other",
    "primaryStarch": "potato",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Kua Kling curry with mushrooms and tempeh",
    "genre": "asian",
    "primaryStarch": "rice",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Creamy turmeric pasta",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Indianish baked beans",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Quinoa miso bowl",
    "genre": "asian",
    "primaryStarch": "quinoa",
    "rating": 5,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Noodle bowl with orange, maple, miso dressing",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Salmon, miso rice, and ginger scallions",
    "genre": "asian",
    "primaryStarch": "rice",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Baingan bharta",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 2,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Red lentil and chickpea curry",
    "genre": "indian",
    "primaryStarch": "rice",
    "rating": 4,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Omelettes",
    "genre": "other",
    "primaryStarch": "",
    "rating": 3,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Chickpea and tomato chhole stew",
    "genre": "indian",
    "primaryStarch": "naan",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 153,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Naan pizzas",
    "genre": "other",
    "primaryStarch": "naan",
    "rating": 3,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-06-25",
    "notes": ""
  },
  {
    "recipe": "Perogies",
    "genre": "other",
    "primaryStarch": "potato",
    "rating": 3,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-01",
    "notes": ""
  },
  {
    "recipe": "African groundnut peanut soup stew",
    "genre": "other",
    "primaryStarch": "",
    "rating": 3,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-01",
    "notes": ""
  },
  {
    "recipe": "Indian spiced lentil cauliflower soup",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-01",
    "notes": ""
  },
  {
    "recipe": "Tex-mex Mexican rice casserole",
    "genre": "other",
    "primaryStarch": "rice",
    "rating": 5,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-01",
    "notes": ""
  },
  {
    "recipe": "Broccoli and cashew cheese burritos",
    "genre": "other",
    "primaryStarch": "quinoa",
    "rating": 5,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-01",
    "notes": ""
  },
  {
    "recipe": "Tomato-basil pasta",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 4,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-01",
    "notes": ""
  },
  {
    "recipe": "One-pot lemon, pea, and asparagus pasta",
    "genre": "italian",
    "primaryStarch": "pasta",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "New potato, tortilla, and halloumi",
    "genre": "other",
    "primaryStarch": "potato",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Cucumber salad with sichuan pepper",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Spicy sichuan noodles",
    "genre": "asian",
    "primaryStarch": "noodles",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "Florence Fabricant",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Halloumi and new potato tortilla",
    "genre": "mediterranean",
    "primaryStarch": "potato",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Summer taco salad",
    "genre": "mexican",
    "primaryStarch": "",
    "rating": 5,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 251,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Thai-Chinese-style spicy peanut noodles",
    "genre": "asian",
    "primaryStarch": "noodles",
    "rating": 4,
    "source": "other",
    "sourceDetails": "thai cookbook (Heather's iPhone)",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Anna Jones pizza dough",
    "genre": "other",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Roast avocado tacos",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 281,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Roti stirfry with sweet potatoes, cauliflower, and broccoli",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Harissa and lime halloumi flatbreads",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 272,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Dhal-baked eggs",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 314,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Roast cauliflower cheddar soup",
    "genre": "other",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 319,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Sri Lankan squash dhal",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 332,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Poached egg and mushrooms on brioche",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Gordon Ramsay masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Saag paneer spanikopita",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Paranthas",
    "genre": "side",
    "primaryStarch": "",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Beetroot borani and harissa yogurt",
    "genre": "side",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 234,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": "Yogurt on pg. 273, flatbread on page 456"
  },
  {
    "recipe": "Salmon and roasted beet salad",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "oh she glows 1",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 113,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Beet and avocado kachumber",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Munammara and hummus",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Ottolenghi masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Grilled caesar salad with crispy chickpeas",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 248,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Kaddu sweet-and-sour butternut squash",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 115,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Smoky Thai massaman curry with shrimp",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 409,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Tray bake with feta",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 352,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": "Can swap out veggies"
  },
  {
    "recipe": "Figs, ricotta, radicchio and almonds",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 364,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Raclette",
    "genre": "other",
    "primaryStarch": "potato",
    "rating": 5,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Moroccan lentil soup",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "from Kim by email",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Santa fe soup",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "from Kim by email",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Party bao buns",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 410,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Artichoke dip",
    "genre": "side",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "from Vicki by email",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Almond sauce stir fry",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "oh she glows 2",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Eggs benedict with mushroom bacon",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": "Mushroom bacon from the vegan mac and cheese recipe"
  },
  {
    "recipe": "Dinner rolls",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "America's Test Kitchen",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Shakshuka",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 2,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Gochujang shrimp",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "Ali Slaigle",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Split pea soup",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "rebar",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 119,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Butternut squash soup",
    "genre": "other",
    "primaryStarch": "",
    "rating": 2,
    "source": "rebar",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 134,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Buttery beets and grapefruit",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 92,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Stir fry celery with peanuts",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 104,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Pan-roasted Romanesco with toasted nuts and crispy bits",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 90,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Charred broccoli salad with dates",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 106,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Spaghetti with artichokes",
    "genre": "italian",
    "primaryStarch": "",
    "rating": 4,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 137,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Smokey feta and pea spread",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Ottolenghi masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Smacked cucumber salad with sumac pickled onions",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Ottolenghi masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Roasted cauliflower and harissa",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Ottolenghi masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Salmon and prawns in tomato sauce with tahini",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Ottolenghi masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Pasta with butternut squash and yogurt sauce",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Ottolenghi masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Shatta",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Ottolenghi masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Herb shakshuka",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 3,
    "source": "other",
    "sourceDetails": "Ottolenghi masterclass",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Fox-style chickpeas",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 232,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Salmon with cauliflower tartare",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 186,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Caramelized onion dhal",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "indianish",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 160,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": ""
  },
  {
    "recipe": "Saganaki with greek salad",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Olive Tomato website",
    "url": "https://www.olivetomato.com/saganaki-greek-fried-cheese/",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2020-08-05",
    "notes": "Heather's Greek salad"
  },
  {
    "recipe": "Cajun potatoes and fish",
    "genre": "other",
    "primaryStarch": "potato",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Heather",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "Carla Lalli Music for fish advice"
  },
  {
    "recipe": "Ginger salmon",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1021938-ginger-dill-salmon?action=click&module=RecipeBox&pgType=recipebox-page&region=all&rank=9",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Baked peanut tofu",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1020530-baked-tofu-with-peanut-sauce-and-coconut-lime-rice?action=click&module=RecipeBox&pgType=recipebox-page&region=all&rank=47",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Thai curry risotto",
    "genre": "asian",
    "primaryStarch": "rice",
    "rating": 3,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1021947-thai-curry-risotto-with-squash-and-green-beans?action=click&module=RecipeBox&pgType=recipebox-page&region=all&rank=8",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Mango and shrimp tacos",
    "genre": "mexican",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1013581-shrimp-and-mango-tacos?action=click&module=RecipeBox&pgType=recipebox-page&region=all&rank=12",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Genmai miso soup",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "rebar",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 122,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Salmon and couscous salad",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1021970-salmon-and-couscous-salad-with-cucumber-feta-dressing?action=click&module=RecipeBox&pgType=recipebox-page&region=all&rank=7",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Arugula fig naan pizzas",
    "genre": "other",
    "primaryStarch": "naan",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 236,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "Unclear origins, Heather concoction"
  },
  {
    "recipe": "Roasted veggies",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Heather",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Mushroom ragout",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Ottolenghi",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "From Plenty, just put on toast"
  },
  {
    "recipe": "Black pepper tofu",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Ottolenghi",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "From Plenty"
  },
  {
    "recipe": "King Arthur slider buns",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "King Arthur Flour website",
    "url": "https://www.kingarthurbaking.com/recipes/beautiful-burger-buns-recipe",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Sesame & green olive bars",
    "genre": "side",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 90,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Easter egg veggie tart",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 120,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Soba with mango and eggplant",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Ottolenghi",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "From Plenty"
  },
  {
    "recipe": "Crushed new potatoes, broccoli, and blood orange cream",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 110,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": ""
  },
  {
    "recipe": "Yotam's OG shakshuka",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Ottolenghi",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "From Plenty"
  },
  {
    "recipe": "Saffron cauliflower",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Ottolenghi",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "From Plenty"
  },
  {
    "recipe": "Potato vindaloo",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Ottolenghi",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "From Plenty"
  },
  {
    "recipe": "Pasta with sausages, mushrooms, and vodka sauce",
    "genre": "italian",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Jane Cooks",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "Random Instagram influencer"
  },
  {
    "recipe": "Onion quiche",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Heather",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-06",
    "notes": "With caramelized onions"
  },
  {
    "recipe": "Butter chicken nachos",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Hot for Food 2",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": "Took out from the library"
  },
  {
    "recipe": "Jackfruit sabzi",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022260-jackfruit-sabzi?action=click&module=RecipeBox&pgType=recipebox-page&region=all&rank=3",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "One pot mac & cheese with buffalo chicken",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Hot for Food 2",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Korean BBQ burritos with cauliflower rice",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Hot for Food 2",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Ben Ben Noodles",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 77,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Paneer aloo gobi",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 111,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Summer pilau",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 3,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 141,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Mapo tofu",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 171,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Cauliflower ceviche",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022397-cauliflower-ceviche?action=click&module=RecipeBox&pgType=recipebox-page&region=cauliflower&rank=1",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Charred courgettes and tofu with lemon, basil, and goat cheese on flatbread",
    "genre": "other",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 170,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": "BBQ courgette instructions on page 275"
  },
  {
    "recipe": "Peanut broccoli and broccoli pad thai",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 178,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Green onion and ginger noodles",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 1,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 90,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Indian butter chickpeas",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1020739-indian-butter-chickpeas?action=click&module=RecipeBox&pgType=recipebox-page&region=indian&rank=1",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Mouth-numbing sichuan noodles with red cabbage and chili oil",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 81,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Pizza dough",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "King Arthur Flour website",
    "url": "https://www.kingarthurbaking.com/recipes/the-easiest-pizza-youll-ever-make-recipe",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": "Freeze rolled out, not in a ball"
  },
  {
    "recipe": "Lentil and split pea soup",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "rebar",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 172,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Shrimp bisque",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1021453-spicy-tomato-coconut-bisque-with-shrimp-and-mushrooms?action=click&module=RecipeBox&pgType=recipebox-page&region=all&rank=1",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Instant pot green curry",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Graham's recipe",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Crispy bean cakes with harissa, lemon, and herbs",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "Yewande Komolafe",
    "url": "https://cooking.nytimes.com/recipes/1021370-crispy-bean-cakes-with-harissa-lemon-and-herbs?action=click&module=RecipeBox&pgType=recipebox-page&region=all&rank=0",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Cranberry nut bread",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/3044-cranberry-nut-bread",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Cranberry muffins",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "",
    "url": "https://www.crumbtopbaking.com/partridgeberry-muffins/#tasty-recipes-6803-jump-target",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Red bean stew with friend onions and cilantro",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1019186-red-bean-stew-with-fried-onions-and-cilantro?action=click&module=Local%20Search%20Recipe%20Card&pgType=search&rank=2",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Potato and pea curry",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "",
    "url": "https://www.bbc.co.uk/food/recipes/potato_and_pea_curry_19537",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Mozzarella with charred snap peas",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 87,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": "Note: don't use pizza mozzarrella, get fresh mozza"
  },
  {
    "recipe": "Omelet with ricotta",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 120,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Pasta with cauliflower and big bread crumbs",
    "genre": "italian",
    "primaryStarch": "",
    "rating": 4,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 141,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Any fruit galette",
    "genre": "dessert",
    "primaryStarch": "",
    "rating": 5,
    "source": "where cooking begins",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 242,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Tarts with caramelized onion",
    "genre": "side",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1017870-little-onion-tarts-with-gorgonzola-and-walnuts?action=click&module=Local%20Search%20Recipe%20Card&pgType=search&rank=7",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Roasted beet salad with gorgonzola",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "other",
    "sourceDetails": "Heather",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2021-11-07",
    "notes": ""
  },
  {
    "recipe": "Tuna and pasta with green onions",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 3,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 349,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Corn on the cob with gochujang and miso butter",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 3,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 247,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Cauliflower with lemon, garlic, and anchovies",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 312,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Shrimp and chickpea salad",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022161-spicy-shrimp-and-chickpea-salad",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Satay tofu skewers",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022599-tofu-vegetable-satay-with-peanut-sauce",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Kung pao cauliflower",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 235,
    "multiplier": 2,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Chili tofu",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 177,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Mushroom mapu tofu",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 171,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Thai cabbage slaw",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "rebar",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 28,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Scallion pancakes",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Lucky Peach 101 Asian Recipes",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Instant Pot Green Moong Dal",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Piping Pot Curry",
    "url": "https://pipingpotcurry.com/green-moong-dal-pressure-cooker/",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Slow-Cooker Cauliflower, Potato and White Bean Soup",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022831-slow-cooker-cauliflower-potato-and-white-bean-soup",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Crispy tofu with balsamic tomatoes",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022852-crispy-tofu-with-balsamic-tomatoes",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Dave's Dal",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 329,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Coconut curry with vegetables",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 240,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Shrimp with corn and potatoes",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 273,
    "multiplier": 2,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Soupy seafood (fish with noodles)",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 269,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Priya and Dave's Fried Rice",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 4,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 235,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Gochujang potatoes",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 3,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 243,
    "multiplier": 1,
    "dateAdded": "2022-09-17",
    "notes": ""
  },
  {
    "recipe": "Gentle potato chowder",
    "genre": "other",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 22,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Quick carrot dhal",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 2,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 46,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Eshkeneh-yeh Adas (Lentil Egg Drop Soup)",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 3,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022871-eshkeneh-yeh-adas-lentil-egg-drop-soup",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": "Haven't made it yet; revise rating if so!"
  },
  {
    "recipe": "One-pan squash, capter, and kale pasta",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 3,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 51,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": "Haven't made it yet; revise rating if so!"
  },
  {
    "recipe": "Rutabaga laksa",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 75,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Pasta with olives and feta",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 3,
    "source": "other",
    "sourceDetails": "Heather",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Shiitake pho",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 3,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 87,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Sweet potato momos",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 34,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Bombay rolls",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 203,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Dirty rice with mushrooms",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1023001-dirty-rice-with-mushrooms",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Crepes",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "",
    "url": "",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Cacio e pepe",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 338,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Lemony shrimp and bean stew",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1020828-lemony-shrimp-and-bean-stew",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Black dal",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 5,
    "source": "east",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 215,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Potato masala dosa",
    "genre": "indian",
    "primaryStarch": "",
    "rating": 4,
    "source": "modern cook's year",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 124,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Yotam's pesto pasta with white beans + halloumi",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022548-pesto-pasta-with-white-beans-and-halloumi",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Shrimp escabeche",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 3,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 274,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Spicy chipotle salmon bowls",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Half Baked Harvest",
    "url": "https://www.halfbakedharvest.com/chipotle-honey-salmon-bowls/",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Glazed vegetables",
    "genre": "other",
    "primaryStarch": "",
    "rating": 3,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 299,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Veggie satay skewers",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1022599-tofu-vegetable-satay-with-peanut-sauce",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": "Satay sauce was unreal"
  },
  {
    "recipe": "Flatbread pizza with homemade dough",
    "genre": "other",
    "primaryStarch": "",
    "rating": 2,
    "source": "cooking at home",
    "sourceDetails": "",
    "url": "",
    "pageNumber": 353,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Sesame-Brown Butter Udon Noodles",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 3,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1023588-sesame-brown-butter-udon-noodles",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Vegetable maafé",
    "genre": "other",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1023570-vegetable-maafe",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Paloma drinks",
    "genre": "other",
    "primaryStarch": "",
    "rating": 5,
    "source": "other",
    "sourceDetails": "Half Baked Harvest",
    "url": "https://www.halfbakedharvest.com/spicy-pomegranate-ginger-paloma/",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Cauliflower cashew coconut curry + rice",
    "genre": "asian",
    "primaryStarch": "",
    "rating": 5,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1019911-cauliflower-cashew-pea-and-coconut-curry",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "Chile Crisp Fettuccine Alfredo With Spinach",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1023609-chile-crisp-fettuccine-alfredo-with-spinach",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  },
  {
    "recipe": "One-Pan Shrimp Scampi With Crispy Gnocchi",
    "genre": "mediterranean",
    "primaryStarch": "",
    "rating": 4,
    "source": "nyt cooking",
    "sourceDetails": "",
    "url": "https://cooking.nytimes.com/recipes/1023571-one-pan-shrimp-scampi-with-crispy-gnocchi",
    "pageNumber": null,
    "multiplier": 1,
    "dateAdded": "2022-12-16",
    "notes": ""
  }
];

export default meals;
